import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Home = lazy(() => import('./Home'));
const Azienda = lazy(() => import('./Azienda'));
const Contatti = lazy(() => import('./Contatti'));
const Sitiweb = lazy(() => import('./Sitiweb'));
const Gnam = lazy(() => import('./Gnam'));

function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-EHXZ6VV8VZ'); // Initialize GA with your tracking ID
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search, // Send pageview with path and query string
    });
  }, [location]); // Add location to the dependency array to track location changes

  return null; // This component does not render anything
};

function App() {
  return (
    <Router>
      <PageTracker /> {/* Questo componente si occuperà del tracciamento delle visualizzazioni di pagina */}
      <Suspense fallback={<div> Caricamento in corso...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/Azienda" element={<Azienda />} />
          <Route path="/home/Contatti" element={<Contatti />} />
          <Route path="/home/Sitiweb" element={<Sitiweb />} />
          <Route path="/home/Gnam" element={<Gnam />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;



































